<template>
  <div>
    <headers />
      <div class="mb row" style="padding:15px;padding-top: 90px !important;">
        <div class="col-12">
        
          <div class="tab-box" >
            <ul class="tab-head nav nav-tabs">
              
              <li class="nav-item" data-id="tc4" @click="tab('tc1')" v-bind:class="[curindex=='tc1' ? 'nav-item active show':'nav-item']">
                <a data-toggle="tab" class="nav-link" aria-expanded="true"><div class="lang" v-html="lan.move_plan_list" ></div></a>
              </li> 
              <li class="nav-item" data-id="tc3" @click="tab('tc2')" v-bind:class="[curindex=='tc2' ? 'nav-item active show':'nav-item']">
                <a data-toggle="tab" class="nav-link" aria-expanded="true"><div class="lang" v-html="lan.move_plan_result"></div></a>
              </li> 
            </ul>
            
          </div>
        </div>
   
     
      <div class="tab-content" style="padding: 15px;background:#fff">
          
          <router-link :to="{path:tolink}" class="btn btn-primary waves-effect waves-light btn-back-fixed" v-if="cur_space_id=='' || curindex!='tc5' ">
          <i class="mdi mdi-arrow-left-bold"></i> 一覧
          </router-link>
          
          <div class="embed-responsive-16by9 tab-pane tc9 active" id="tc3" v-if="curindex=='tc1'">
              <div class="col-12">
                <div class="card-box">
                  <div class="left"><img :src="'img/yc.png'">{{lan.move_plan_list}}</div>
                  <div class="right">{{lan.user_money}} {{money}} <i class="fe-map-pin"></i></div>
                  <div class="form-inline md-form form-sm toolbar-search">

<input class="form-control mr-1 w-80" type="search" placeholder="Search" aria-label="Search" v-model="listStrage.query" @keyup.enter="search">

<button type="button" class="btn btn-white" @click="search">
  <i class="fas fa-search" aria-hidden="true"></i>
</button>
</div>
                  <div class="responsive-table-plugin">
                    <div class="d-flex justify-content-center p-5" v-if="isLoading">
                  <div class="spinner-border m-12" role="status">
                    <div class="sr-only">Loading...</div>
                  </div>
                </div>
                      <div class="table-rep-plugin">
                        <div class="table-responsive">
                          <table class="table table-striped focus-on">
                            <thead>
                              <tr>
                                <th>{{lan.company}}</th>
                                <th colspan="4">{{lan.move_filter}}</th>
                                <th colspan="4">{{lan.result}}</th>
                              </tr>
                              <tr>
                                <th></th>
                                <th v-for="tableHeader in tableHeaders" :key="tableHeader" :class="'table-row-' + tableHeader" >{{ tableHeader }}</th>
                                
                              </tr>
                            </thead>
                            <tbody v-if="listData.length>0">
                              <tr v-for="(obj, idNum) in listData" :key="idNum" :class="{ 'focused' : isFocus === idNum }" @click="toggleFocus(idNum)">
                                
                                <td >
                                {{(obj['corp_name']==undefined ?'':obj['corp_name'])}}
                                </td>
                                <td >
                                  <router-link :to="'/corp1/0?dataId=0&dataType=100&dataName='+obj['corp_name']+'&code='+(obj['code']==undefined?'':obj['code'])+'&dataNumber='+obj['corp_number']+'&curindex=tc2&tolink=move_plan'" class="btn btn-default"><i class="fas fa-file-invoice-dollar"></i></router-link>
                                </td>
                                <td >
                                  <router-link :to="'/corp1/0?dataId=0&dataType=100&dataName='+obj['corp_name']+'&code='+(obj['code']==undefined?'':obj['code'])+'&dataNumber='+obj['corp_number']+'&curindex=tc2&tolink=move_plan'" class="btn btn-default"><i class="fe-bar-chart"></i></router-link>
                                </td>
                                <td >
                                  <router-link :to="'/corp1/0?dataId=0&dataType=100&dataName='+obj['corp_name']+'&code='+(obj['code']==undefined?'':obj['code'])+'&dataNumber='+obj['corp_number']+'&curindex=tc2&tolink=move_plan'" class="btn btn-default"> <i class="fe-users"></i></router-link>
                                </td>
                                <td >
                                  <router-link :to="'/corp1/0?dataId=0&dataType=100&dataName='+obj['corp_name']+'&code='+(obj['code']==undefined?'':obj['code'])+'&dataNumber='+obj['corp_number']+'&curindex=tc2&tolink=move_plan'" class="btn btn-default"> <i class="fe-aperture"></i></router-link>
                                </td>
                                <td >
                                  <input class="form-control mr-1 w-80" type="text" placeholder="" aria-label="" v-model="company['key'+obj['code']]" >
                                </td>
                                <td >
                                  <select class="form-control" v-model="yd['key'+obj['code']]" >
                                    <option :value="lan.yd">{{lan.yd}}</option>              
                                    <option :value="lan.stz">{{lan.stz}}</option>  
                                    <option :value="lan.byd">{{lan.byd}}</option>  
                                  </select>
                                </td>
                                <td >
                                  <select class="form-control" v-model="yd_rate['key'+obj['code']]">
                        
                                    <option v-for="(item,index) in rate_array" :key="index" :value="item">{{item}}</option>
                                  </select>
                                  </td>
                                  <td >
                                    <select class="form-control" v-model="yd_rate_grade['key'+obj['code']]" >
                                    <option :value="lan.upgrade">{{lan.upgrade}}</option>              
                                    <option :value="lan.keep">{{lan.keep}}</option> 
                                    <option :value="lan.downgrade">{{lan.downgrade}}</option> 
                                  </select>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div class="p-2">
                            <code v-if="listData.length==0">表示データがありません。</code>
                          </div>
                          <div class="btn-footer">
                          <button class="btn btn-primary waves-effect waves-light btn-back-fixed">{{lan.save}}</button>
                        </div>
                        </div> <!-- end .table-responsive -->
                      </div> <!-- end .table-rep-plugin-->
                    </div>
                </div>
              </div>
          </div>
        
         
          <div class="embed-responsive-16by9 tab-pane tc9 active" id="tc3" v-if="curindex=='tc2'">
              <div class="col-12">
                <div class="card-box">
                  <div class="left"><img :src="'img/yc.png'">{{lan.move_plan_result}}</div>
                  <div class="right">{{lan.user_money}} {{money}} <i class="fe-map-pin"></i></div>
                  <div class="form-inline md-form form-sm toolbar-search">

<input class="form-control mr-1 w-80" type="search" placeholder="Search" aria-label="Search" v-model="listStrage.query" @keyup.enter="search">

<button type="button" class="btn btn-white" @click="search">
  <i class="fas fa-search" aria-hidden="true"></i>
</button>
</div>
                  <div class="responsive-table-plugin">
                    <div class="d-flex justify-content-center p-5" v-if="isLoading">
                  <div class="spinner-border m-12" role="status">
                    <div class="sr-only">Loading...</div>
                  </div>
                </div>
                      <div class="table-rep-plugin">
                        <div class="table-responsive">
                          <table class="table table-striped focus-on">
                            <thead>
                              <tr>
                                <th>{{lan.company}}</th>
                                <th colspan="4">{{lan.move_filter}}</th>
                                <th colspan="4">{{lan.result}}</th>
                              </tr>
                              <tr>
                                <th></th>
                                <th v-for="tableHeader in tableHeaders" :key="tableHeader" :class="'table-row-' + tableHeader" >{{ tableHeader }}</th>
                                
                              </tr>
                            </thead>
                            <tbody v-if="listData.length>0">
                              <tr v-for="(obj, idNum) in listData" :key="idNum" :class="{ 'focused' : isFocus === idNum }" @click="toggleFocus(idNum)">
                                
                                <td >
                                {{(obj['corp_name']==undefined ?'':obj['corp_name'])}}
                                </td>
                                <td >
                                  <router-link :to="'/corp1/0?dataId=0&dataType=100&dataName='+obj['corp_name']+'&code='+(obj['code']==undefined?'':obj['code'])+'&dataNumber='+obj['corp_number']+'&curindex=tc2&tolink=move_plan'" class="btn btn-default"><i class="fas fa-file-invoice-dollar"></i></router-link>
                                </td>
                                <td >
                                  <router-link :to="'/corp1/0?dataId=0&dataType=100&dataName='+obj['corp_name']+'&code='+(obj['code']==undefined?'':obj['code'])+'&dataNumber='+obj['corp_number']+'&curindex=tc2&tolink=move_plan'" class="btn btn-default"><i class="fe-bar-chart"></i></router-link>
                                </td>
                                <td >
                                  <router-link :to="'/corp1/0?dataId=0&dataType=100&dataName='+obj['corp_name']+'&code='+(obj['code']==undefined?'':obj['code'])+'&dataNumber='+obj['corp_number']+'&curindex=tc2&tolink=move_plan'" class="btn btn-default"> <i class="fe-users"></i></router-link>
                                </td>
                                <td >
                                  <router-link :to="'/corp1/0?dataId=0&dataType=100&dataName='+obj['corp_name']+'&code='+(obj['code']==undefined?'':obj['code'])+'&dataNumber='+obj['corp_number']+'&curindex=tc2&tolink=move_plan'" class="btn btn-default"> <i class="fe-aperture"></i></router-link>
                                </td>
                                <td >
                                  {{company['key'+obj['code']]==undefined ? '':company['key'+obj['code']]}}
                                </td>
                                <td >
                                  {{yd['key'+obj['code']]==undefined ? '':yd['key'+obj['code']]}}
                                </td>
                                <td >
                                  {{yd_rate['key'+obj['code']]==undefined ? '':yd_rate['key'+obj['code']]}}
                                </td>
                                <td >
                                  {{yd_rate_grade['key'+obj['code']]==undefined ? '':yd_rate_grade['key'+obj['code']]}}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div class="p-2">
                            <code v-if="listDataResult.length==0">表示データがありません。</code>
                          </div>
                        </div> <!-- end .table-responsive -->
                      </div> <!-- end .table-rep-plugin-->
                    </div>
                </div>
              </div>
          </div>

          </div>
        </div>
  </div>
</template>

<script>
import axios from 'axios';
import { inject } from 'vue';
import Headers from '@/components/Headers.vue'
import 'vue3-carousel/dist/carousel.css';


export default {
  name: 'move_plan',
  components: {
    Headers,
  },
  data() {
    return {
      curindex:'tc1',
      tolink:"/",
      curlink: encodeURIComponent(window.location.href.split('#')[1]),
      cur_lan:localStorage.getItem("cur_lan"),
      lan:inject('global').language_data,lan_help:inject('global').language_help_data,
      listStrage: {
        query: '',
        limit: 10,
        paged: 1,
      },
      money:500,
      tableHeaders: [],
      listData: [Object],
      rate_array:[],
      yd_rate_grade:[],
      yd_rate:[],
      company:[],
      yd:[],
      yds:[],
      results:[],
    } 	
  },
  mounted() {
    this.tableHeaders=[this.lan.financial,
    this.lan.market,
    this.lan.member_hr,
    this.lan.business_environment,
    this.lan.other,
    this.lan.move_result,
    this.lan.move_rate,
    this.lan.move_rate_grade
   

  ];
  for(var i=1;i<=100;i++){
      this.rate_array.push(i+'%');
    }
    this.yds=[this.lan.upgrade,this.lan.keep,this.lan.downgrade];
    this.results=[this.lan.yd,this.lan.byd,this.lan.stz];
  this.search();
     //let language_data=JSON.parse(localStorage.getItem('language_data'));
    //this.lan=language_data
  
    //this.getCorpList();

  },
  methods: {
   
     formart_money:function (num) {
      var result = '', counter = 0;
      num = (num || 0).toString();
      var numa=num.split('.');
      var d=0;
      if(numa[1]!=undefined){
        d=numa[1].length+1;
      }

      for (var i = num.length-d - 1; i >= 0; i--) {
      counter++;
      result = num.charAt(i) + result;
      if (!(counter % 3) && i != 0 && num.charAt(i-1)!='-') { result = ',' + result; }
      }
      return d>0 ?result+'.'+numa[1]:result;
    },
     formart_date:function (num) {
      
      return typeof(num)=='string' ? num.replace(' 00:00:00',''):num;
    },
 
    search: function() {
      this.isLoading = true
  
      axios
        .get('/v1/corps_group/', {
          params: {
            offset: 0, //開始位置
            limit: this.listStrage.limit, //表示件数
            data_type: 'knx', //表示类型
            q: this.listStrage.query, //キーワード
            id_token:localStorage.getItem('id_token'),
          }
        })
        .then(response => (
            this.jsonObj = response.data,
           // this.tableHeaders = this.jsonObj.headers,
            this.listData = this.jsonObj.data,
            this.listDataResult = this.jsonObj.data,
            //this.maxPage = Math.ceil(this.jsonObj.total_count / this.listStrage.limit),
            //this.listStrage.paged = 1,
            this.isLoading = false
          )
        )
        .catch(error => {
            console.log(error),
            this.jsonErr = true,
            this.isLoading = false
          }
        );
    },
  
   
    SaveParams: function() {
      this.saveAlert = 0
      axios
        .put(this.viewParams.apiUrl + '/extended', {
          extended: this.extendParams.extended,
          email: localStorage.getItem('email'),
          id_token:localStorage.getItem('id_token')
        })
        .then(() => {
          this.saveAlert = 1
        })
        .catch(error => {
          this.saveAlert = 9
          console.log(error)
        });
    },
   
    tab:function(tid){
      this.curindex=tid;
      
    }
    

  },beforeUnmount () {
    window.removeEventListener('pagehide', this.backListener)
  },

 
}
</script>
<style lang="scss">
.left,.right{width: 50%;float: left; font-size:24px; color:#012a2d;font-weight: bold;}
.left{
  font-size:30px;
}
.right{
 text-align: right;
}
.btn-footer{margin: 30px;}
.left img{width: 100px;margin-right:30px}
.toolbar-search{width:100%;margin:20px 0;}
.table-responsive th{background: #012a2d;color:#fff}
</style>